"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { LocalTime } from "@egocentric-systems/ts-apis/types/v1/localtime_pb";
import { SaleNotStarted } from "./components/SaleNotStarted";
import { SaleEnded } from "./components/SaleEnded";
import { SaleOpen } from "./components/SaleOpen";
import { useSale } from "~/hooks/useSale";
import { SoldOut } from "./components/SoldOut";

type Props = {
  saleStart: PlainMessage<LocalTime> | undefined;
  saleEnd: PlainMessage<LocalTime> | undefined;
  start: PlainMessage<LocalTime> | undefined;
  end: PlainMessage<LocalTime> | undefined;
  soldOut: boolean;
  soldOutText: string;
  saleEndedText: string;
  saleNotStartedText: string;
  buyTicketText: string;
  className?: string;
};

export default function SaleBadge(props: Props) {
  const { remainingTime, saleEnded, saleNotStarted } = useSale({
    soldOut: props.soldOut,
    saleStart: props.saleStart,
    saleEnd: props.saleEnd,
  });

  if (props.soldOut) {
    return <SoldOut className={props.className} text={props.soldOutText} />;
  }

  if (saleNotStarted) {
    return (
      <SaleNotStarted
        remainingTime={remainingTime}
        text={props.saleNotStartedText}
        className={props.className}
      />
    );
  }

  if (saleEnded) {
    return <SaleEnded className={props.className} text={props.saleEndedText} />;
  }

  return <SaleOpen className={props.className} text={props.buyTicketText} />;
}
