"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { LocalTime } from "@egocentric-systems/ts-apis/types/v1/localtime_pb";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { localTimeToDayjs } from "~/lib/utils";
import isBetween from "dayjs/plugin/isBetween";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useDayjsLocale } from "./useLocale";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isBetween);

type Props = {
  soldOut: boolean;
  saleStart: PlainMessage<LocalTime> | undefined;
  saleEnd: PlainMessage<LocalTime> | undefined;
};

function calculateRemainingTime(time: PlainMessage<LocalTime>): string | null {
  const now = dayjs();
  const salesStart = dayjs.tz(time.time, time.timezone);

  if (salesStart.isBefore(now)) {
    return null;
  }

  const diff = salesStart.diff(now);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const seconds = Math.floor((diff / 1000) % 60);
  const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;

  if (days > 0) {
    return `${days}T ${hoursString}:${minutesString}:${secondsString}`;
  }

  return `${hoursString}:${minutesString}:${secondsString}`;
}

export function useSale(props: Props) {
  const [saleNotStarted, setSaleNotStarted] = useState(false);
  const [saleEnded, setSaleEnded] = useState(false);
  const [remainingTime, setRemainingTime] = useState<string | null>(null);
  useDayjsLocale();

  useEffect(() => {
    const check = () => {
      if (props.soldOut) {
        setSaleNotStarted(false);
        setSaleEnded(false);
        setRemainingTime(null);
        return;
      }

      if (!props.saleEnd || !props.saleStart) {
        setSaleNotStarted(false);
        setSaleEnded(false);
        setRemainingTime(null);
        return;
      }

      const now = dayjs();
      const saleStart = localTimeToDayjs(props.saleStart);
      const saleEnd = localTimeToDayjs(props.saleEnd);

      if (now.isBefore(saleStart, "second")) {
        const time = calculateRemainingTime(props.saleStart);
        setSaleNotStarted(true);
        setSaleEnded(false);
        setRemainingTime(time);
        return;
      }

      if (now.isAfter(saleEnd, "second")) {
        setSaleNotStarted(false);
        setSaleEnded(true);
        setRemainingTime(null);
        return;
      }

      if (now.isBetween(saleStart, saleEnd, "second")) {
        setSaleNotStarted(false);
        setSaleEnded(false);
        setRemainingTime(null);
        return;
      }
    };

    check();

    const interval = setInterval(check, 1000);

    return () => clearInterval(interval);
  }, [props.saleEnd, props.saleStart, props.soldOut]);

  return {
    saleNotStarted,
    saleEnded,
    remainingTime,
  };
}
