import { cn } from "~/lib/utils";

type SaleOpenProps = {
  text: string;
  className?: string;
};

export function SaleOpen(props: SaleOpenProps) {
  return (
    <span
      className={cn(
        "rounded-full bg-white px-4 py-1 text-xs font-bold text-black",
        props.className,
      )}
    >
      {props.text}
    </span>
  );
}
