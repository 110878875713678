import { cn } from "~/lib/utils";

type Props = {
  text: string;
  className?: string;
};

export function SaleEnded({ className, text }: Props) {
  return (
    <div
      className={cn(
        "rounded-full bg-white px-4 py-1 text-xs font-bold text-black",
        className,
      )}
    >
      {text}
    </div>
  );
}
