"use client";

import { AnimatePresence, motion } from "framer-motion";
import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import { cn, getLargestVariant } from "~/lib/utils";
import { useMediaQuery } from "usehooks-ts";
import Link from "next/link";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import { buttonVariants } from "~/components/ui/button";

type Props = {
  series: PlainMessage<EventSeries>[];
  color: string;
  view_series: string;
};

let timeout: NodeJS.Timeout | null = null;
const INTERVAL_TIME = process.env.NODE_ENV === "development" ? 4000 : 12000;

export default function Slider({ view_series, color, series }: Props) {
  const [index, setIndex] = useState(0);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const availableSeries = useMemo(
    () => series.slice(0, isDesktop ? 15 : 10),
    [isDesktop, series],
  );

  useEffect(() => {
    if (timeout) {
      clearInterval(timeout);
    }

    timeout = setTimeout(() => {
      if (index === availableSeries.length - 1) {
        setIndex(0);
        return;
      }

      setIndex(index + 1);
    }, INTERVAL_TIME);

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [availableSeries.length, index]);

  if (availableSeries.length === 0) {
    return null;
  }

  if (availableSeries.length === 1) {
    const thumbnail = getLargestVariant(availableSeries[0].thumbnail);

    if (!thumbnail) {
      return null;
    }

    return (
      <section className="relative">
        <Image
          src={thumbnail.url}
          width={thumbnail.width}
          height={thumbnail.height}
          alt={availableSeries[0].name ?? ""}
          className="h-full max-h-[800px] w-full object-cover object-center"
        />
      </section>
    );
  }

  return (
    <section className="relative h-[400px] w-full max-w-full overflow-hidden md:h-[700px]">
      <AnimatePresence>
        {availableSeries.map((series, i) => {
          const thumb = getLargestVariant(series.thumbnail);

          return (
            <motion.div
              key={series.id + "-image-" + i}
              initial={{ opacity: 0 }}
              animate={{ opacity: i === index ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0 h-full w-full overflow-hidden"
            >
              <div
                key={series.id + "-image-scale-" + i}
                className="h-full w-full"
              >
                <Image
                  priority
                  src={thumb?.url ?? ""}
                  width={thumb?.width ?? 0}
                  height={thumb?.height ?? 0}
                  alt={series.name ?? ""}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <div
        className="absolute inset-0 h-full w-full"
        style={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(250,250,250,0) 100%)",
        }}
      />
      <div className="container relative mx-auto flex h-full flex-col">
        <div className="mt-auto flex flex-col pb-14">
          <motion.p
            key={availableSeries[index].id + "-title-" + index}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              stiffness: 400,
              damping: 10,
              duration: 0.3,
              delay: 0.3,
            }}
            className="text-xl font-bold text-white md:text-2xl"
          >
            {availableSeries[index].name}
          </motion.p>
          <motion.p
            key={availableSeries[index].id + "-intro-" + index}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.3,
              stiffness: 500,
              damping: 10,
              delay: 0.2,
            }}
            className="max-w-full pr-4 text-sm text-white md:max-w-[500px] md:pr-0 md:text-md lg:max-w-[600px]"
          >
            {availableSeries[index].intro}
          </motion.p>
          <motion.div
            key={availableSeries[index].id + "-button-" + index}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, stiffness: 500, damping: 10 }}
            className="mt-4"
          >
            <Link
              className={cn(buttonVariants(), "font-bold")}
              style={{ backgroundColor: color }}
              href={generatePath(Paths.SERIES, {
                seriesId: availableSeries[index].id,
              })}
            >
              {view_series}
            </Link>
          </motion.div>
        </div>
        <div className="absolute bottom-8 left-8 right-4 flex h-[4px] flex-row flex-nowrap gap-4">
          {availableSeries.map((_, i) => (
            <button
              onClick={() => {
                setIndex(i);
              }}
              key={i}
              className="h-[20px]"
            >
              <motion.div
                className="h-[10px] overflow-hidden rounded-full bg-white bg-opacity-50"
                initial={{ width: 20 }}
                animate={{ width: index === i ? 35 : 20 }}
              >
                <motion.div
                  key={availableSeries[index].id}
                  animate={{
                    width: index === i ? ["0%", "100%"] : "0%",
                    transition: {
                      duration: index === i ? INTERVAL_TIME / 1000 : 0,
                      type: "keyframes",
                    },
                  }}
                  style={{ backgroundColor: color }}
                  className="h-full"
                />
              </motion.div>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}
