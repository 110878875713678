import { cn } from "~/lib/utils";

type SaleNotStartedProps = {
  text: string;
  remainingTime: string | null;
  className?: string;
};

export function SaleNotStarted({
  className,
  remainingTime,
  text,
}: SaleNotStartedProps) {
  return (
    <div
      className={cn(
        "rounded-full bg-white px-4 py-1 text-xs font-bold text-black",
        className,
      )}
    >
      {text} <span className="text-pearDark">{remainingTime}</span>
    </div>
  );
}
